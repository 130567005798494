import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import InformationSection from "../components/InformationSection";
import PricingSection from "../components/PricingSection";
import TrendingCities from "../components/TrendingCities";
import GetListedSection from "../components/GetListedSection";

const Packages = () => {
  const [trendingCities, setTrendingCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();

  const cityData = [
    {
      name: "Islamabad",
      headline: "The beautiful capital of Pakistan",
      imageUrl: "https://ik.imagekit.io/9vwcvqozx/islamabad.png?updatedAt=1732244413779",
    },
    {
      name: "Rawalpindi",
      headline: "The Historic Heart of Pakistan",
      imageUrl: "https://ik.imagekit.io/9vwcvqozx/rawalpindi.png?updatedAt=1732244416670",
    },
    { name: "Lahore", headline: "The city of gardens", imageUrl: "https://ik.imagekit.io/9vwcvqozx/lahore.png?updatedAt=1732244413746" },
    {
      name: "Karachi",
      headline: "The city of lights",
      imageUrl: "https://ik.imagekit.io/9vwcvqozx/karachi.png?updatedAt=1732244413754",
    },
    {
      name: "Muzaffarabad",
      headline: "Scenic Beauty",
      imageUrl: "https://ik.imagekit.io/9vwcvqozx/muzaffarabad.png?updatedAt=1732244413755",
    },
  ];

  useEffect(() => {
    if (!apiUrl) {
      console.error("API URL is not defined!");
      setError("API URL is missing. Please configure it in the .env file.");
      setLoading(false);
      return;
    }

    axios
      .get(`${apiUrl}/shared/cities`)
      .then((response) => {
        const cities = response.data.cities.map((city) => {
          const cityInfo = cityData.find((c) => c.name === city) || {};
          return {
            heading: city,
            subheading: cityInfo.headline || "Explore this city!",
            imageUrl: cityInfo.imageUrl || "https://via.placeholder.com/150",
            destination: `/discover`,
          };
        });
        setTrendingCities(cities);
        setError(null);
      })
      .catch((err) => {
        console.error("Error fetching trending cities:", err);
        setError("Failed to load trending cities. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [apiUrl]);

  if (loading) {
    return <div className="min-h-screen flex items-center justify-center text-white">Loading...</div>;
  }

  if (error) {
    return <div className="min-h-screen flex items-center justify-center text-red-500">{error}</div>;
  }
  return (
    <div className="bg-[#04092C] min-h-screen text-white flex flex-col m-0 p-0">
      <Header searchBar={true} />
      <HeroSection />
      <TrendingCities data={trendingCities} />
      <InformationSection />
      <div id="pricing">
        <PricingSection />
      </div>
      <GetListedSection/>
      <Footer />
    </div>
  );
};

export default Packages;
