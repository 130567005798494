import React, { useState } from 'react';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'What is MoQah?',
      answer:
        'MoQah is a platform designed to connect users with exceptional events, artists, and venues for unforgettable experiences.',
    },
    {
      question: 'How can I book an event?',
      answer:
        'You can browse events by date, city, or artist on our homepage, then click on the event you’re interested in to book.',
    },
    {
      question: 'Is there any fee to list an event?',
      answer:
        'Listing an event is absolutely free! We encourage event organizers to reach more audiences through our platform.',
    },
    {
      question: 'How can I contact support?',
      answer:
        'You can reach out to us via the contact form on our website, or email us directly at support@moqah.pk.',
    },
    {
      question: 'Does MoQah support international events?',
      answer:
        'Yes, MoQah supports both local and international events to connect audiences with global experiences.',
    },
    {
      question: 'Can I get reminders for upcoming events?',
      answer:
        'Absolutely! Sign up with us to receive email or SMS notifications for upcoming events based on your preferences.',
    },
    {
      question: 'Is MoQah accessible on mobile?',
      answer:
        'Yes, MoQah is fully optimized for mobile devices, allowing you to browse and book events on the go.',
    },
    {
      question: 'How do I become an event partner?',
      answer:
        'Simply visit the “Create Your Event” section on our website and fill out the necessary details to join as a partner.',
    },
    {
      question: 'Can I filter events by location?',
      answer:
        'Yes, our platform allows you to search and filter events by city, venue, or proximity to your location.',
    },
    {
      question: 'Are tickets refundable?',
      answer:
        'Refund policies depend on the event organizer. Please check the ticket details before purchase.',
    },
    {
      question: 'What payment methods are accepted?',
      answer:
        'We accept a variety of payment methods, including credit cards, debit cards, and mobile wallets.',
    },
    {
      question: 'Can I rate and review events?',
      answer:
        'Yes, after attending an event, you can leave a rating and review to help others make informed decisions.',
    },
    {
      question: 'How can I promote my event on MoQah?',
      answer:
        'You can choose one of our promotional packages to feature your event on our homepage and in email campaigns.',
    },
    {
      question: 'Does MoQah charge a commission?',
      answer:
        'A small commission fee may apply to ticket sales to maintain our platform and services.',
    },
    {
      question: 'Can I see event details before booking?',
      answer:
        'Yes, every event page provides comprehensive details, including dates, times, venue information, and more.',
    },
    {
      question: 'Are there family-friendly events on MoQah?',
      answer:
        'Yes, we offer a wide selection of events tailored for families, including kids-friendly activities and more.',
    },
    {
      question: 'What makes MoQah different from other platforms?',
      answer:
        'MoQah stands out due to its intuitive interface, wide event selection, and a commitment to user satisfaction.',
    },
    {
      question: 'Can I cancel my booking?',
      answer:
        'Cancellation policies depend on the event organizer. Please refer to the terms mentioned during booking.',
    },
    {
      question: 'Do you offer corporate event planning?',
      answer:
        'Yes, MoQah provides solutions for corporate events, including venue booking and promotional services.',
    },
    {
      question: 'Can I follow specific artists or venues?',
      answer:
        'Yes, our platform allows you to follow artists and venues to get notified about their upcoming events.',
    },
    {
      question: 'Is my payment information secure?',
      answer:
        'Yes, MoQah employs industry-standard security measures to protect your payment details.',
    },
    {
      question: 'Are there discounts for bulk ticket purchases?',
      answer:
        'Yes, some event organizers offer discounts for group bookings. Check the event details for more information.',
    },
    {
      question: 'How do I report an issue with an event?',
      answer:
        'If you encounter any issues, please contact us through the support page with your event details.',
    },
    {
      question: 'Can I save events for later viewing?',
      answer:
        'Yes, create an account with MoQah to save your favorite events and access them later.',
    },
    {
      question: 'Does MoQah host its own events?',
      answer:
        'While we primarily connect users to external events, we occasionally host special events curated by our team.',
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-200 min-h-screen">
      {/* Header */}
      <div className="bg-gradient-to-r from-purple-600 to-pink-600 py-20 text-center text-white">
        <h1 className="text-6xl font-extrabold tracking-tight drop-shadow-md">
          FAQ
        </h1>
        <p className="mt-4 text-2xl font-medium">
          Find Answers to Your Questions
        </p>
      </div>

      {/* FAQ Section */}
      <div className="container mx-auto py-16 px-6 grid gap-6 lg:grid-cols-2">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`bg-white shadow-lg rounded-lg p-6 mb-4 cursor-pointer transition-transform duration-500 ${
              activeIndex === index ? 'transform scale-105 bg-blue-50' : ''
            }`}
            onClick={() => toggleFAQ(index)}
          >
            <h2 className="text-2xl font-semibold text-blue-600 flex justify-between items-center">
              {faq.question}
              <span
                className={`text-lg transition-transform ${
                  activeIndex === index ? 'rotate-180' : ''
                }`}
              >
                ▼
              </span>
            </h2>
            {activeIndex === index && (
              <p className="mt-4 text-gray-700 transition-all ease-in-out">
                {faq.answer}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
