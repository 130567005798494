import React, { useState } from "react";
import PurpleButton from "./PurpleButton";
import axios from "axios";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [responseMessage, setResponseMessage] = useState("");
  const iconPath = process.env.PUBLIC_URL + "/icons/";
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/user/contact-us`, formData);
      setResponseMessage(response.data.message); // Set response message on success
    } catch (error) {
      console.error("Failed to send message", error);
      setResponseMessage("Failed to send message. Please try again."); // Set error message
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="contact-header">
        <h6>Get Started</h6>
        <PurpleButton text="Contact Us" />
      </div>
      <div className="contact-headline">
        <div className="headline">
          <h6>Get in touch with us.</h6>
          <h6>We are here to assist you.</h6>
        </div>
        <div className="contact-social-icons">
        <a href="https://www.instagram.com/moqah.pk?igsh=cTYyOTRmY25hczU=" target="_blank" rel="noopener noreferrer">
          <img className="ig-icon" src={iconPath + "instagram.svg"} alt="ig-icon" />
        </a>
        <a href="https://www.linkedin.com/company/moqah-pk/" target="_blank" rel="noopener noreferrer">
          <img className="linkedin-icon" src={iconPath + "linkedin.svg"} alt="linkedin-icon" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61559000177779&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
          <img className="fb-icon" src={iconPath + "facebook.svg"} alt="fb-icon" />
        </a>
      </div>
      </div>
      <div className="contact-form">
        <div className="input-container">
          <h6 className="input-label">Your Name</h6>
          <input type="text" name="name" placeholder="Your First Name" value={formData.name} onChange={handleChange} required />
        </div>
        <div className="input-container">
          <h6 className="input-label">Email Address</h6>
          <input type="email" name="email" placeholder="username@example.com" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="input-container">
          <h6 className="input-label">Phone Number</h6>
          <input type="text" name="phone" placeholder="Your Phone Number" value={formData.phone} onChange={handleChange} />
        </div>
        <div className="message input-container">
          <h6 className="input-label">Message</h6>
          <textarea name="message" rows="5" placeholder="Your message here" value={formData.message} onChange={handleChange} required></textarea>
        </div>
      </div>
      <div className="contact-footer">
        <h6 className="footer-headline">We are always happy to assist you</h6>
        <button type="submit" className="contact-button">Leave us a Message ➜</button>
      </div>
      {responseMessage && <p className="response-message">{responseMessage}</p>}
    </form>
  );
};

export default ContactUs;
