import React, { startTransition } from "react";
import { useNavigate } from "react-router-dom";

const GetListedSection = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    startTransition(() => {
      navigate("/add-event");
    });
  };

  return (
    <div className="relative bg-gradient-to-r from-blue-500 to-blue-400 text-white min-h-[20vh] md:min-h-[40vh] px-6 md:px-20 lg:px-60 py-10 overflow-hidden flex items-center justify-between">
      <div className="absolute hidden md:block top-4 left-4 w-20 h-20 bg-pink-300 rounded-full opacity-50 animate-pulse"></div>
      <div className="absolute hidden md:block top-6 right-10 w-32 h-32 bg-green-300 rounded-full opacity-70"></div>
      <div className="absolute hidden md:block bottom-10 left-16 w-28 h-28 bg-purple-400 rounded-full opacity-50"></div>
      <div className="absolute hidden md:block bottom-8 right-20 w-24 h-24 bg-red-400 rounded-full opacity-50"></div>

      <div className="w-full md:w-1/2 z-10">
        <h1 className="text-3xl md:text-4xl font-extrabold leading-snug mb-6">
          Let’s Get you Listed!!!
        </h1>
        <button
          onClick={handleNavigation}
          className="px-6 py-3 bg-white text-blue-600 font-semibold rounded-full shadow-lg hover:bg-blue-100 transition-transform transform hover:scale-105"
        >
          GET STARTED!
        </button>
      </div>
      <div className="hidden md:block relative w-1/2 h-full">
        <div className="absolute top-0 right-0 w-40 h-40 bg-blue-600 rounded-full opacity-30 blur-md"></div>
        <div className="absolute bottom-10 left-16 w-28 h-28 bg-blue-300 rounded-full opacity-40 blur-md"></div>
        <div className="absolute top-20 right-8 w-24 h-24 bg-pink-200 rounded-full opacity-60 animate-bounce"></div>
      </div>
    </div>
  );
};

export default GetListedSection;
