import React, { useEffect, useState , startTransition} from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/CoroselContainer.css';
import Corosel from './corosel';

// This will have all the pictures of the events
const CoroselContainer = () => {
  const [allEventList, setAllEventList] = useState([]);
  const navigate = useNavigate(); 

  // Fetch event images from the backend
  useEffect(() => {
    const fetchEventImages = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;  // Fetch the base API URL from environment variables
        const response = await fetch(`${apiUrl}/packages/carousel`);  // Make request to the `/carousel` endpoint
        if (response.ok) {
          const imageUrls = await response.json();
          // console.log("Urls", imageUrls);
          setAllEventList(imageUrls); // Set the fetched image URLs to state
        } else {
          console.error('Failed to fetch images');
        }
      } catch (error) {
        console.error('Error fetching event images:', error);
      }
    };

    fetchEventImages(); // Call the function on component mount
  }, []);

  // These are rows data for the CoroselItems
  let rowData = [[], [], []];

  allEventList.map((imageUrl, i) => {
    if (i <= 9) {
      rowData[0].push(imageUrl);
    } else if (i > 9 && i <= 19) {
      rowData[1].push(imageUrl);
    } else {
      rowData[2].push(imageUrl);
    }
  });

  // Set Row Direction as you wish
  let direction = ['left', 'right', 'left'];

  return (
    <>
      <div className="coroselContainer mb-5">
        <div className="mycorosel">
          <Corosel imgData={rowData} direction={direction} />
        </div>
      </div>
      <div className="coroselButton mb-5">
      <button
          type="button"
          onClick={() =>
            startTransition(() => {
              navigate('/discover');
            })
          }
        >
          View recent works
        </button>
      </div>
    </>
  );
};

export default CoroselContainer;