import React, { useEffect, useState } from "react";
import axios from "axios";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Header from "../components/Header";

const AboutUs = () => {
    const [stats, setStats] = useState([{ totalEventCount: 0 }, { activeEventCount: 0 }]);
    const [trendingData, setTrendingData] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
  
    useEffect(() => {
      const fetchStats = async () => {
        try {
          const activeResponse = await axios.get(`${apiUrl}/home/active-events-count`);
          const totalResponse = await axios.get(`${apiUrl}/home/total-events`);
          
          setStats([
            { totalEventCount: totalResponse.data.EventCount },
            { activeEventCount: activeResponse.data.activeEventCount },
          ]);
        } catch (error) {
          console.error("Error fetching event statistics:", error);
        }
      };

      fetchStats();
    }, [apiUrl]);
  
    useEffect(() => {
      const fetchTrendingEvents = async () => {
        try {
          const response = await axios.get(`${apiUrl}/home/trending-events`);
          const processedEvents = response.data.map(item => ({
            id: item.id,
            name: item.name,
            category: item.category,
            city: item.city,
            date: new Date(item.date),
            imageUrl: item.adv_banner,
            ticket_price: item.ticket_price ? 
              Math.min(...Object.values(item.ticket_price)) : 0
          }));

          setTrendingData(processedEvents);
        } catch (error) {
          console.error("Error fetching trending events:", error);
          setTrendingData([]);
        }
      };

      fetchTrendingEvents();
    }, [apiUrl]);

    return (
      <>
        <Header searchBar={false} />
        <div className="about-us-page bg-gray-50">
          {/* Hero Section */}
          <section className="relative bg-gradient-to-r from-indigo-500 to-purple-600 text-white py-20 px-4">
            <div className="container mx-auto text-center">
              <h1 className="text-5xl md:text-6xl font-bold mb-6 animate-fade-in">
                About <span className="text-yellow-300">MOQAH</span>
              </h1>
              <p className="text-lg md:text-xl leading-relaxed max-w-3xl mx-auto opacity-90">
                Connecting communities through innovative event experiences. 
                Discover, celebrate, and create unforgettable memories together.
              </p>
            </div>
          </section>

          {/* Banner Section */}
          <section className="py-12 px-4">
            <div className="container mx-auto">
              <Banner
                subheading="Empowering Communities Through Events"
                stats={stats}
                data={trendingData}
              />
            </div>
          </section>

          {/* Mission and Vision */}
          <section className="bg-white py-16 px-4">
            <div className="container mx-auto grid md:grid-cols-2 gap-8 items-center">
              <div className="text-center md:text-left">
                <h2 className="text-3xl md:text-4xl font-bold mb-6 text-purple-700">
                  Our Mission
                </h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                  To revolutionize event discovery by creating a seamless, 
                  user-friendly platform that connects people with extraordinary 
                  experiences across diverse categories and cities.
                </p>
              </div>
              <div className="text-center md:text-left">
                <h2 className="text-3xl md:text-4xl font-bold mb-6 text-yellow-600">
                  Our Vision
                </h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                  To become the definitive platform for event exploration, 
                  empowering individuals to discover, participate, and create 
                  meaningful connections through shared experiences.
                </p>
              </div>
            </div>
          </section>

          {/* Detailed Description Section */}
          <section className="bg-gray-100 py-16 px-4">
            <div className="container mx-auto max-w-4xl">
              <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center text-purple-800">
                Discover the MOQAH Difference
              </h2>
              
              <div className="space-y-6 text-lg text-gray-700 leading-relaxed">
                <p>
                  MOQAH is more than just an event discovery platform – we're a community-driven ecosystem designed to transform how people connect, learn, and experience life's most memorable moments. Our innovative approach combines cutting-edge technology with a deep understanding of local event landscapes.
                </p>
                
                <p>
                  We understand that every event is unique, whether it's a local festival, a professional workshop, a family-friendly activity, or a wellness seminar. Our platform is meticulously crafted to cater to diverse interests across major Pakistani cities including Islamabad, Karachi, Lahore, Rawalpindi, and beyond.
                </p>
                
                <p>
                  By leveraging advanced algorithms and user-centric design, we provide personalized event recommendations that match individual preferences. Our commitment goes beyond mere event listing – we aim to create meaningful connections that enrich communities and spark new experiences.
                </p>
                
                <div className="bg-white p-6 rounded-lg shadow-md">
                  <h3 className="text-2xl font-semibold text-purple-700 mb-4">
                    Why Choose MOQAH?
                  </h3>
                  <ul className="list-disc list-inside text-gray-700 space-y-2">
                    <li>Comprehensive event coverage across multiple categories</li>
                    <li>User-friendly interface with advanced filtering options</li>
                    <li>Real-time updates and personalized recommendations</li>
                    <li>Support for local event creators and community organizers</li>
                    <li>Seamless ticket purchasing and event registration</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* Call to Action */}
          <section className="py-16 bg-gradient-to-r from-indigo-600 to-purple-700 text-white">
            <div className="container mx-auto text-center">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">
                Your Next Adventure Awaits
              </h2>
              <p className="text-lg md:text-xl mb-8">
                Join thousands discovering incredible events every day.
              </p>
              <button
                className="px-10 py-4 bg-yellow-400 text-black font-semibold rounded-full shadow-lg hover:bg-yellow-500 transition-all duration-300 transform hover:scale-110"
                onClick={() => window.location.href = "/home"}
              >
                Explore Events
              </button>
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
};

export default AboutUs;