import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import QRScannerDialog from "./QRScannerDialog";
const TicketVerification = ({tickets, activity}) => {
  // State variables
  const [ticketData, setTicketData] = useState(tickets ?? []);
  const [activityData, setActivityData] = useState({
    totalAttendees: activity.total_tickets ?? 0,
    presentAttendees: activity.checked_in ?? 0,
    checkedOutAttendees: activity.checked_out ?? 0,
    canceledAttendees: activity.cancelled ?? 0,
  });
  const [ticketNumber, setTicketNumber] = useState("");
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isQRScannerOpen, setIsQRScannerOpen] = useState(false);

  const rowsPerPage = 10;
  const iconPath = process.env.PUBLIC_URL + "/icons/";

  const mockTickets = [
    {
      id: 100,
      quantity: 2,
      status: "Paid",
      type: "Platinum",
      time: "2013-11-28 00:00",
      transactionId: "20170303",
      validated: true,
    },
    {
      id: 101,
      quantity: 5,
      status: "Unpaid",
      type: "Silver",
      time: "2020-01-12 00:00",
      transactionId: "20100419",
      validated: true,
    },
    {
      id: 102,
      quantity: 2,
      status: "Unpaid",
      type: "Silver",
      time: "2015-06-24 00:00",
      transactionId: "20160410",
      validated: true,
    },
    {
      id: 103,
      quantity: 2,
      status: "Paid",
      type: "Platinum",
      time: "2019-07-01 00:00",
      transactionId: "20180925",
      validated: false,
    },
    {
      id: 104,
      quantity: 5,
      status: "Unpaid",
      type: "Silver",
      time: "2016-06-12 00:00",
      transactionId: "20190205",
      validated: false,
    },
    {
      id: 105,
      quantity: 9,
      status: "Paid",
      type: "Silver",
      time: "2012-05-25 00:00",
      transactionId: "20181118",
      validated: true,
    },
    {
      id: 106,
      quantity: 7,
      status: "Paid",
      type: "Gold",
      time: "2020-07-28 00:00",
      transactionId: "20190905",
      validated: true,
    },
    {
      id: 107,
      quantity: 3,
      status: "Paid",
      type: "Gold",
      time: "2013-08-10 00:00",
      transactionId: "20110902",
      validated: true,
    },
    {
      id: 108,
      quantity: 10,
      status: "Unpaid",
      type: "Silver",
      time: "2013-05-21 00:00",
      transactionId: "20191031",
      validated: false,
    },
    {
      id: 109,
      quantity: 2,
      status: "Paid",
      type: "Silver",
      time: "2016-08-16 00:00",
      transactionId: "20120311",
      validated: false,
    },
    {
      id: 110,
      quantity: 2,
      status: "Paid",
      type: "Silver",
      time: "2011-11-25 00:00",
      transactionId: "20121105",
      validated: true,
    },
    {
      id: 111,
      quantity: 8,
      status: "Paid",
      type: "Silver",
      time: "2014-09-07 00:00",
      transactionId: "20190205",
      validated: false,
    },
    {
      id: 112,
      quantity: 3,
      status: "Paid",
      type: "Gold",
      time: "2019-11-06 00:00",
      transactionId: "20101112",
      validated: true,
    },
    {
      id: 113,
      quantity: 1,
      status: "Unpaid",
      type: "Gold",
      time: "2011-07-20 00:00",
      transactionId: "20171228",
      validated: true,
    },
    {
      id: 114,
      quantity: 6,
      status: "Paid",
      type: "Silver",
      time: "2013-04-13 00:00",
      transactionId: "20190605",
      validated: true,
    },
    {
      id: 115,
      quantity: 8,
      status: "Unpaid",
      type: "Platinum",
      time: "2018-07-12 00:00",
      transactionId: "20140616",
      validated: false,
    },
    {
      id: 116,
      quantity: 8,
      status: "Paid",
      type: "Gold",
      time: "2010-03-10 00:00",
      transactionId: "20100301",
      validated: true,
    },
    {
      id: 117,
      quantity: 3,
      status: "Unpaid",
      type: "Platinum",
      time: "2014-10-23 00:00",
      transactionId: "20100724",
      validated: false,
    },
    {
      id: 118,
      quantity: 2,
      status: "Paid",
      type: "Silver",
      time: "2011-07-18 00:00",
      transactionId: "20150911",
      validated: true,
    },
    {
      id: 119,
      quantity: 9,
      status: "Paid",
      type: "Platinum",
      time: "2014-06-30 00:00",
      transactionId: "20151017",
      validated: false,
    },
  ];

  // useEffect(() => {
  //   // For Backend only
  //   /*
  //   const fetchTicketsAndActivityData = async () => {
  //     try {
  //       const ticketsResponse = await fetch("https://your-backend-url.com/api/tickets");
  //       const tickets = await ticketsResponse.json();
  //       setTicketData(tickets);

  //       const activityResponse = await fetch("https://your-backend-url.com/api/activity-tracking");
  //       const activity = await activityResponse.json();
  //       setActivityData(activity);
  //     } catch (err) {
  //       console.error("Error fetching data:", err.message);
  //       setError("Failed to fetch data. Please try again later.");
  //     }
  //   };

  //   fetchTicketsAndActivityData();
  //   */

  //   // Mock data for now
  //   setTicketData(mockTickets);
  // }, []);

  // Handle scan button click
  const handleScanClick = () => {
    setIsQRScannerOpen(true);
  };
  // Handle Ticket Verification
  const handleVerify = () => {
    const updatedTickets = ticketData.map((ticket) => {
      if (ticket.transaction_number === ticketNumber) {
        setError("");
        return { ...ticket, status: "Paid", validated: true };
      }
      return ticket;
    });

    const ticketFound = ticketData.some(
      (t) => t.transaction_number === ticketNumber
    );
    if (!ticketFound) setError("Transaction ID not found. Try again!");
    setTicketData(updatedTickets);
    setTicketNumber("");
  };

  // Calculate the percentage of present attendees
  const calculatePercentage = () => {
    if (activityData.totalAttendees > 0) {
      return (
        (activityData.presentAttendees / activityData.totalAttendees) *
        100
      ).toFixed(2);
    }
    return "0.00";
  };

  // Filter tickets based on search query
  const filteredTickets = ticketData.filter(
    (ticket) =>
      ticket.ticket_id.toString().includes(searchQuery) ||
      ticket.transaction_number.includes(searchQuery) ||
      ticket.ticket_type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination Logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredTickets.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredTickets.length / rowsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Handle Report Download (CSV Logic)
  const handleDownloadReport = () => {
    const csvData = [
      [
        "Ticket ID",
        "Quantity",
        "Ticket Status",
        "Ticket Type",
        "Purchase Time",
        "Transaction ID",
        "Validation Status",
      ],
      ...ticketData.map((ticket) => [
        ticket.ticket_id,
        ticket.quantity,
        ticket.check_in ? 'Checked In' : 'Unchecked',
        ticket.ticket_type,
        ticket.payment_date,
        ticket.transaction_number,
        ticket.paid ? "Validated" : "Not Validated",
      ]),
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");
    const link = document.createElement("a");
    link.href = encodeURI(csvContent);
    link.download = "ticket_report.csv";
    link.click();
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen flex flex-col gap-6">
      {/* Activity Tracking Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="font-bold text-gray-800 mb-4">Activity Tracking</h3>
        <div className="relative w-full bg-gray-200 h-4 rounded-full">
          <div
            className="absolute top-0 left-0 h-4 bg-green-500 rounded-full"
            style={{ width: `${calculatePercentage()}%` }}
          ></div>
        </div>
        <p className="text-green-700 font-bold mt-2">
          {calculatePercentage()}%
        </p>
        <p className="text-lg font-bold mt-4">
          Total Attendees: {activityData.totalAttendees}
        </p>
        <div className="flex flex-wrap gap-4 mt-4">
          <div className="bg-green-100 p-2 rounded text-green-600">
            Present attendees: {activityData.presentAttendees}
          </div>
          <div className="bg-yellow-100 p-2 rounded text-yellow-600">
            Checked out attendees: {activityData.checkedOutAttendees}
          </div>
          <div className="bg-red-100 p-2 rounded text-red-600">
            Canceled attendees: {activityData.canceledAttendees}
          </div>
        </div>
      </div>

      {/* Buttons Section */}
      <div className="flex flex-wrap gap-4 justify-center">
        <button
          onClick={handleDownloadReport}
          className="bg-gray-200 flex items-center justify-center gap-2 px-4 py-2 rounded-lg hover:bg-gray-300 transition"
        >
          <img
            src={`${iconPath}report-icon.svg`}
            alt="Report Icon"
            className="w-5 h-5"
          />
          Report
        </button>
        <button
          className="bg-blue-600 flex items-center justify-center gap-2 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition shadow"
          onClick={handleScanClick}
        >
          <img
            src={`${iconPath}scan-icon.svg`}
            alt="Scan Icon"
            className="w-5 h-5"
          />
          Scan Now
        </button>
      </div>

      <QRScannerDialog
        isOpen={isQRScannerOpen}
        onClose={() => setIsQRScannerOpen(false)}
      />

      {/* Verify Ticket */}
      <div className="bg-white p-6 rounded-lg shadow flex flex-col items-center">
        <label className="block text-gray-700 font-medium mb-2">
          Enter your Ticket Number
        </label>
        <input
          type="text"
          placeholder="123234546897"
          value={ticketNumber}
          onChange={(e) => setTicketNumber(e.target.value)}
          className="border border-gray-300 px-3 py-2 rounded-lg w-full max-w-md focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={handleVerify}
          className="bg-gray-200 text-gray-700 px-4 py-2 mt-2 rounded-lg hover:bg-gray-300 transition w-full max-w-md"
        >
          Verify
        </button>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      </div>

      {/* Tickets Table */}
      <div className="mt-6 bg-white p-4 rounded-lg shadow overflow-x-auto">
        <h3 className="text-lg font-bold text-gray-800 mb-4">List of Users</h3>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search by ID, Type, Transaction ID..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 px-3 py-2 rounded-lg w-full max-w-sm focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th className="border p-2">Ticket ID</th>
              <th className="border p-2">Quantity</th>
              <th className="border p-2">Ticket Status</th>
              <th className="border p-2">Ticket Type</th>
              <th className="border p-2">Purchase Time</th>
              <th className="border p-2">Transaction ID</th>
              <th className="border p-2">Validation Status</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((ticket) => (
              <tr key={ticket.ticket_id} className="even:bg-gray-50">
                <td className="border p-2 text-center">{ticket.ticket_id}</td>
                <td className="border p-2 text-center">{ticket.quantity}</td>
                <td className="border p-2 text-center">{ticket.check_in ? "Checked In" : "Not Checked In"}</td>
                <td className="border p-2 text-center">{ticket.ticket_type}</td>
                <td className="border p-2 text-center">{new Date(ticket.payment_date).toLocaleString()}</td>
                <td className="border p-2 text-center">
                  {ticket.transaction_number}
                </td>
                <td className="border p-2 text-center">
                  {ticket.paid ? (
                    <span className="text-green-500">✔</span>
                  ) : (
                    <span className="text-red-500">✖</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center mt-4">
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="bg-gray-200 px-3 py-1 rounded hover:bg-gray-300"
          >
            Prev
          </button>

          <div className="flex space-x-2">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`px-3 py-1 rounded ${
                  currentPage === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 hover:bg-gray-300"
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>

          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className="bg-gray-200 px-3 py-1 rounded hover:bg-gray-300"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketVerification;
