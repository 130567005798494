import React, { useEffect, useState } from "react";
import { Buffer } from 'buffer';
import CryptoJS from 'crypto-js';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import QRCode from "qrcode";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiEyp8kv8JHgFVrJJnecmNE.woff2",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2",
      fontWeight: "bold",
    },
  ],
});
Font.register({
  family: "Poppins-bold",
  src: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2",
});
const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: "#fff",
    color: "white",
    display: "flex",
    alignItems: "center",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    backgroundColor: "#303458",
    borderRadius: "15px",
    paddingTop: 20,
  },
  header: {
    marginBottom: 20,
    textAlign: "left",
    width: "100%",
    paddingLeft: 15,
  },
  invoiceNumber: {
    fontSize: 20,
    marginBottom: 10,
  },
  eventTitle: {
    fontSize: 20,
    fontFamily: "Poppins-bold",
    // fontWeight: "bold",
    marginBottom: 10,
    color: "#cac8c6",
  },
  eventImageContainer: {
    height: "40%",
  },
  eventImage: {
    height: "100%",
    width: "auto",
  },
  eventDetails: {
    fontSize: 14,
    marginBottom: 10,
  },
  eventDetailsItem: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
  },
  icon: {
    width: "10px",
  },
  eventDetailsItemText: {
    fontSize: 10,
    fontWeight: 700,
  },
  ticketContainer: {
    marginTop: 20,
    paddingTop: 10,
  },
  ticketContainerIndividual: {
    backgroundColor: "#464a6f",
    borderRadius: 10,
    marginBottom: 15,
    display: "flex",
    flexDirection: "row",
    gap: "10",
    padding: "20",
  },
  containerEventName: {
    fontSize: 12,
    color: "#cac8c6",
    marginBottom: 5,
  },
  containerEventDetails: {
    marginBottom: 10,
    marginTop: 10,
  },
  ticketContainerIndividualLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  ticketContainerIndividualRight: {},
  ticketNumber: {
    fontSize: 10,
    marginBottom: 5,
    textTransform: "uppercase",
  },
  qrCodeContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  qrCode: {
    width: 100,
    height: 100,
    borderRadius: 7,
  },
  qrCodeHeading: {
    textAlign: "center",
    fontSize: 10,
  },
});

const PdfTicket = ({ invoice, eventData, ticketSelections, userDetails }) => {
  // const [qrCodeUri, setQrCodeUri] = useState("");

  // useEffect(() => {
  //   // Generate QR code as a base64 image
  //   QRCode.toDataURL(invoice || "undefined")
  //     .then((url) => {
  //       setQrCodeUri(url);
  //     })
  //     .catch((err) => console.error("Error generating QR code", err));
  // }, [invoice]);

  const getEncryptionKey = () => {
    // Use SHA-256 to hash the secret key to get a consistent 32-byte key
    // return crypto.createHash('sha256')
    //   .update(process.env.SECRET_KEY)
    //   .digest();
    // return CryptoJS.SHA256(process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    const key = CryptoJS.SHA256(process.env.REACT_APP_SECRET_KEY);
    return CryptoJS.enc.Hex.parse(key.toString(CryptoJS.enc.Hex));
  }
  
  // const decrypt = (encryptedData, iv) => {
  //   const key = Buffer.from(getEncryptionKey(), 'utf-8');
  //   const decipher = crypto.createDecipheriv('aes-256-cbc', key, Buffer.from(iv, 'base64'));
  //   let decrypted = decipher.update(encryptedData, 'base64', 'utf8');
  //   decrypted += decipher.final('utf8');
  //   return decrypted;
  // }

  const decrypt = (encryptedData, iv) => {
    const key = getEncryptionKey(); // Binary encryption key
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
      iv: CryptoJS.enc.Base64.parse(iv), // Parse the IV from Base64
      mode: CryptoJS.mode.CBC, // Ensure the encryption mode matches
      padding: CryptoJS.pad.Pkcs7, // Default padding for AES
    });
    return decrypted.toString(CryptoJS.enc.Utf8); // Convert decrypted data to UTF-8 string
  };

  const generateQRCode = async (qrCodeContent) => {
    const decryptedQRCode = decrypt(qrCodeContent.qr_code_id, qrCodeContent.decryption_key);
    // const ticketInfo = JSON.stringify({
    //   ticket_id: qrCodeContent.ticket_id,
    //   qr_code_id: qrCodeContent.qr_code_id,
    //   user_id: qrCodeContent.user_id,
    //   event_id: qrCodeContent.event_id,
    // })
    const qrCode = await QRCode.toDataURL(decryptedQRCode);
    return qrCode;
  }

  return (
    <Document>
      <Page style={styles.page}>
        {/* Header */}
        <View style={styles.section}>
          <View style={styles.header}>
            <Text style={styles.invoiceNumber}>Invoice# {invoice}</Text>
            {/* <View style={styles.eventImageContainer}>
              <Image
                style={styles.eventImage}
                src={eventData?.backgroundImage}
              />
            </View> */}
            <Text style={styles.eventTitle}>{eventData?.name}</Text>
            <View style={styles.eventDetails}>
              <View style={styles.eventDetailsItem}>
                <Image
                  src={`${process.env.PUBLIC_URL}/icons/map-pin-white.svg`}
                  alt="location"
                  style={styles.icon}
                />
                <Text style={styles.eventDetailsItemText}>
                  {eventData?.venue}
                </Text>
              </View>
              <View style={styles.eventDetailsItem}>
                <Image
                  src={`${process.env.PUBLIC_URL}/icons/map-pin-white.svg`}
                  alt="date"
                  style={styles.icon}
                />
                <Text style={styles.eventDetailsItemText}>
                  {eventData?.date}
                </Text>
              </View>
            </View>
          </View>

          {/* Tickets */}
          {/* <View style={styles.ticketContainer}>
            {ticketSelections.map((ticket, index) => (
              <View key={ticket.id}>
                <Text style={styles.ticketNumber}>Ticket {index + 1}</Text>
                <View style={styles.ticketContainerIndividual}>
                  <View style={styles.ticketContainerIndividualLeft}>
                    <Text>{ticket.id}</Text>
                    <Text style={styles.containerEventName}>
                      {eventData?.name}
                    </Text>
                    <Text style={styles.eventDetailsItemText}>
                      Name:{" "}
                      {`${userDetails.first_name} ${userDetails.last_name}`}
                    </Text>
                    <Text style={styles.eventDetailsItemText}>
                      Email: {userDetails.email}
                    </Text>
                    <View style={styles.containerEventDetails}>
                      <View style={styles.eventDetailsItem}>
                        <Image
                          src="https://ik.imagekit.io/9vwcvqozx/map-pin-white.svg?updatedAt=1734197458001"
                          alt="location"
                          style={styles.icon}
                        />
                        <Text style={styles.eventDetailsItemText}>
                          {eventData?.venue}
                        </Text>
                      </View>
                      <View style={styles.eventDetailsItem}>
                        <Image
                          src="https://ik.imagekit.io/9vwcvqozx/clock-white.svg?updatedAt=1734197591609"
                          alt="date"
                          style={styles.icon}
                        />
                        <Text style={styles.eventDetailsItemText}>
                          {eventData?.date}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.ticketContainerIndividualRight}>
                    {qrCodeUri && (
                      <View style={styles.qrCodeContainer}>
                        <Text style={styles.qrCodeHeading}>Scan Me</Text>
                        <Image style={styles.qrCode} src={qrCodeUri} />
                      </View>
                    )}
                  </View>
                </View>
              </View>
            ))}
          </View> */}

          <View style={styles.ticketContainer}>
            {ticketSelections.map((ticket, index) => (
              <View key={ticket.ticketID}>
                <Text style={styles.ticketNumber}>Ticket {index + 1}</Text>
                <View style={styles.ticketContainerIndividual}>
                  <View style={styles.ticketContainerIndividualLeft}>
                    <Text>{ticket.ticketType}</Text>
                    <Text style={styles.containerEventName}>
                      {eventData?.name}
                    </Text>
                    <Text style={styles.eventDetailsItemText}>
                      Name:{" "}
                      {`${userDetails.first_name} ${userDetails.last_name}`}
                    </Text>
                    <Text style={styles.eventDetailsItemText}>
                      Email: {userDetails.email}
                    </Text>
                    <View style={styles.containerEventDetails}>
                      <View style={styles.eventDetailsItem}>
                        <Image
                          src="https://ik.imagekit.io/9vwcvqozx/map-pin-white.svg?updatedAt=1734197458001"
                          alt="location"
                          style={styles.icon}
                        />
                        <Text style={styles.eventDetailsItemText}>
                          {eventData?.venue}
                        </Text>
                      </View>
                      <View style={styles.eventDetailsItem}>
                        <Image
                          src="https://ik.imagekit.io/9vwcvqozx/clock-white.svg?updatedAt=1734197591609"
                          alt="date"
                          style={styles.icon}
                        />
                        <Text style={styles.eventDetailsItemText}>
                          {eventData?.date}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.ticketContainerIndividualRight}>
                    
                      <View style={styles.qrCodeContainer}>
                        <Text style={styles.qrCodeHeading}>Scan Me</Text>
                        {/* <Text>{ticket.qr_code}</Text>
                        <Text>{decrypt(ticket.qr_code, ticket.qr_code_iv)}</Text> */}
                        <Image style={styles.qrCode} src={() => generateQRCode({ticket_id: ticket.ticketID, qr_code_id: ticket.qr_code, decryption_key: ticket.qr_code_iv, user_id: ticket.userID, event_id: ticket.eventID })} />
                      </View>
                    
                  </View>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfTicket;

// const App = () => {
//   const invoice = '123456';
//   const eventTitle = 'React Conference 2024';
//   const location = 'Istanbul, Turkey';
//   const date = 'December 10, 2024';
//   const tickets = [
//     { name: 'General Admission', price: '$50' },
//     { name: 'VIP Access', price: '$120' },
//   ];

//   const handleOpenPDF = async () => {
//     // Create the PDF blob
//     const doc = <MyDocument invoice={invoice} eventTitle={eventTitle} location={location} date={date} tickets={tickets} />;
//     const asBlob = await pdf(doc).toBlob();

//     // Open the PDF in a new tab
//     const pdfURL = URL.createObjectURL(asBlob);
//     window.open(pdfURL, '_blank');
//   };

//   return (
//     <div>
//       <button onClick={handleOpenPDF}>Open PDF</button>
//     </div>
//   );
// };

// export default App;
