import React, { useState } from "react";
import PurchaseOrderSummary from "./PurchaseOrderSummary";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PdfTicket from "./PdfTicket";
import { pdf } from "@react-pdf/renderer";

const PaymentInstructions = ({
  eventData,
  selectedPaymentMethod,
  prevStep,
  ticketSelections,
  ticketOptions,
  invoiceNumber,
  generateInvoiceNumber,
  userDetails,
}) => {
  const navigate = useNavigate();
  const [transactionNumber, setTransactionNumber] = useState("");
  const [file, setFile] = useState(null); // Use state for the file
  const [errorText, setErrorText] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  let totalPrice = 0;

  const generateQRCodeID = (ticketID) => {
    const timestamp = Date.now();
    const randomPart = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
    const eventPrefix = eventData?.id?.toString().padStart(4, '0') || '0000';
    const ticketQRCode = `${ticketID}-${userDetails.id}-${eventPrefix}-${timestamp}-${randomPart}`;
    
    return ticketQRCode;
  };

  const getToken = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;
    else return token;
  }

  const ticketTypes = () => {
    let ticketTypes = "";
    ticketSelections.forEach(
      (selection) =>
        (ticketTypes += `${selection.qty} x ${
          ticketOptions.find((option) => option.id === selection.id).type
        }, `)
    );
    return ticketTypes.replace(/,\s*$/, "");
  };

  const ticketPrices = () => {
    let ticketPrices = "";
    console.log(ticketSelections);
    ticketSelections.forEach((selection) => {
      const price = ticketOptions.find(
        (option) => option.id === selection.id
      ).price;
      totalPrice += price * selection.qty;
      ticketPrices += `${selection.qty} x Rs. ${price}, `;
    });
    return ticketPrices.replace(/,\s*$/, "");
  };

  const handleConfirmPayment = async () => {
    let attachment = null;

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = async () => {
        attachment = reader.result.split(",")[1];
        await sendPaymentData(attachment);
      };

      reader.onerror = () => {
        console.error("Error reading file.");
      };

      return;
    }

    await sendPaymentData(attachment);
  };

  const sendPaymentData = async (attachment) => {
    const paymentData = {
      tickets: ticketSelections.reduce((acc, selection) => {
        acc[selection.id] = selection.qty;
        return acc;
      }, {}),
      invoice_number: invoiceNumber || generateInvoiceNumber(),
      payment_method: selectedPaymentMethod,
      price: totalPrice,
      // payment_date: Date.now(),
      user_id: userDetails.id,
      event_id: eventData.id,
      transaction_number: transactionNumber,
      user_email: userDetails.email,
      attachment: attachment, // Base64 attachment or null
      // ticket_qr_codes: ticketSelections.map((selection) => {
      //     var codes = [];
      //     for (var i = 0; i < selection.qty; i++) {
      //       codes.push({[`${i}-${selection.id}`]: generateQRCodeID(selection.id)})
      //     }
      //   return {[selection.id]: codes};
      //   }),
    };
  
    try {
      const token = getToken();
      if (!token) window.location.href('/login');

      // const pdfDoc = await pdf(<PdfTicket invoice={invoiceNumber} eventData={eventData} ticketSelections={ticketSelections} ticketOptions={ticketOptions} userDetails={userDetails} />).toBlob();
      // const formData = new FormData();
      // formData.append("pdf", pdfDoc, "ticket.pdf");
      const response = await axios.post(`${apiUrl}/ticket/payments`, paymentData, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });
  
      if (response.status === 201) {
        const purchasedTickets = response.data.tickets;
        console.log('the response is');
        console.log(purchasedTickets);
        console.log('the ticket selections are');
        console.log(ticketSelections);
        navigate("/purchase-confirmation", {
          state: {
            invoiceNumber,
            eventData,
            totalPrice,
            purchasedTickets,
            ticketOptions,
            userDetails,
            attachment
          },
        });
        return response.data;
      }
    } catch (error) {
      console.error("Error during payment:", error);
      throw error;
    }
  };

  const handleCancel = () => {
    window.location.href = "/";
  };

  return (
    <div className="purchase-payment-instructions-and-order-summary">
      <div className="purchase-payment-instruction-container">
        <h2 className="heading">Your Invoice Number:</h2>
        <h2 className="invoice-number">
          {invoiceNumber === "" ? generateInvoiceNumber() : invoiceNumber}
        </h2>
        <div className="payment-instructions-container">
          <h2 className="payment-instructions-heading">
            How to pay with {selectedPaymentMethod}:
          </h2>
          <p className="payment-instructions-text">
            1. Open your Digit wallet application
          </p>
          <p className="payment-instructions-text">
            2. Select Bank Transfer option
          </p>
          <p className="payment-instructions-text">
            3. Select "{selectedPaymentMethod}" as bank payment method.
          </p>
          <p className="payment-instructions-text">
            4. Enter our account number 94809280293803.
          </p>
          <p className="payment-instructions-text">
            5. Confirm our details and pay with ease.
          </p>
          <p className="payment-instructions-text">
            6. Upload your payment receipt for confirmation.
          </p>
        </div>
        <div className="input-container">
          <h6 className="input-label">Enter your Transaction Number: </h6>
          <input
            type="text"
            name="transaction-number"
            value={transactionNumber}
            onChange={(e) => setTransactionNumber(e.target.value)}
          />
        </div>
        <div className="receipt-upload-container">
          <h2 className="heading">Upload your receipt</h2>
          <label
            htmlFor="receipt-upload-input"
            className="receipt-upload-label"
          >
            {file ? (
              <div className="receipt-upload-label-success">
                <img
                  src={
                    process.env.PUBLIC_URL + "/icons/upload_confirmation.svg"
                  }
                  alt="Tick Icon"
                />
                <span className="receipt-file-name">{file.name}</span>
              </div>
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/icons/upload.svg"}
                alt="Upload Icon"
              />
            )}
          </label>
          <input
            id="receipt-upload-input"
            className="receipt-upload-input"
            type="file"
            accept="application/pdf"
            style={{ display: "none" }}
            onChange={(e) => {
              const uploadedFile = e.target.files[0];
              if (uploadedFile.size <= 5242880) {
                // 5MB in bytes
                setFile(uploadedFile);
              } else {
                setErrorText("File size exceeds 5MB. Please upload a smaller file.");
              }
            }}
          />
          {errorText && <p className="error-text">{errorText}</p>}
        </div>
        <div className="payment-instructions-actions-button-container">
          <button
            className="payment-instructions-confirm-button"
            disabled={transactionNumber === ""}
            onClick={handleConfirmPayment}
          >
            Confirm your Payment
          </button>
          <button
            className="payment-instructions-cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
      <PurchaseOrderSummary
        eventData={eventData}
        ticketTypes={ticketTypes()}
        ticketPrices={ticketPrices()}
        totalPrice={totalPrice}
      />
    </div>
  );
};

export default PaymentInstructions;
