import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useInputFilter } from "../context/FilterContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SearchForm = () => {
  const [inputSearchTerm, setSearchTerm] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const { inputFilterDispatch } = useInputFilter();
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;
  const iconsPath = process.env.PUBLIC_URL + "/icons/";

  const [locationOptions, setLocationOptions] = useState([
    // "Islamabad",
    // "Lahore",
    // "Rawalpindi",
    // "Muzaffarabad",
  ]);

  const toggleAdvancedOptions = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  useEffect(() => {
    const getCityList = async () => {
      try {
        const response = await axios.get(`${apiUrl}/shared/cities`);
        if (response.status === 200) {
          setLocationOptions(response.data.cities);
        }
      } catch (error) {
        console.error("Error fetching location options:", error);
      }
    };

    getCityList();
  }, []);

  const handleSearch = async () => {
    try {
      // // Format the date to 'yyyy-MM-dd' without converting to ISO string
      // const formattedDate =
      //   selectedDate !== null && selectedDate !== undefined
      //     ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1)
      //         .toString()
      //         .padStart(2, "0")}-${selectedDate
      //         .getDate()
      //         .toString()
      //         .padStart(2, "0")
      //         .toString()}`
      //     : null;
      // setSelectedDate(formattedDate);
      // const response = await axios.get("http://localhost:5000/shared/search", {
      //   params: {
      //     searchTerm: inputSearchTerm,
      //     date: formattedDate, // Use formatted date here
      //   },
      // });
      // const searchData = response.data;
      // console.log("Search results:", searchData);
      // Dispatch your filter action here if needed
      inputFilterDispatch({
        payload: { inputSearchTerm, zipCode, selectedDate },
      });
      navigate("/discover");
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="search-container">
      
      {/* shows up in mobile view */}
      <div className="search-form-mobile">
        <div className="general-input-container">
          <div className="input-container">
            <img src={iconsPath + "search.svg"} alt="Search icon" />
            <input
              type="text"
              placeholder="Search by Event, Artist, Venue"
              value={inputSearchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <img
            src={iconsPath + "chevron_down.svg"}
            alt="Chevron down icon"
            width="30px"
            className={`expand-icon ${showAdvancedOptions ? "rotate-icon" : ""}`}
            onClick={toggleAdvancedOptions}
          />
        </div>
        {showAdvancedOptions && (
          <div
            className={`advanced-options ${showAdvancedOptions ? "show" : ""}`}
          >
            <div className="input-container">
              <img src={iconsPath + "map-pin-blue.svg"} alt="Map pin icon" />
              <select
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                onKeyDown={handleKeyDown}
                className={zipCode === "" ? "dropdown-empty" : "dropdown"}
              >
                <option value="" className="default-option">
                  Select City
                </option>
                {locationOptions.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-container">
              <div className="date">
                <img
                  src={iconsPath + "calendar-blue.svg"}
                  alt="Calendar icon"
                />
                <DatePicker
                  className="date-picker"
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Date"
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </div>
        )}
        <button
          type="submit"
          className="search-button"
          onClick={handleSearch}
          onKeyDown={handleKeyDown}
        >
          Search
        </button>
      </div>

      {/* shows up in desktop view */}
      <div className="search-form">
        <div className="general-input-container">
          <div className="input-container">
            <img src={iconsPath + "search.svg"} alt="Search icon" />
            <input
              type="text"
              placeholder="Search by Event, Artist, Venue"
              value={inputSearchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className="input-container">
          <img src={iconsPath + "map-pin-blue.svg"} alt="Map pin icon" />
          <select
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            onKeyDown={handleKeyDown}
            className={zipCode === "" ? "dropdown-empty" : "dropdown"}
          >
            <option value="" className="default-option">
              Select City
            </option>
            {locationOptions.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div className="input-container">
          <div className="date">
            <img src={iconsPath + "calendar-blue.svg"} alt="Calendar icon" />
            <DatePicker
              className="date-picker"
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="Date"
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <button
          type="submit"
          className="search-button"
          onClick={handleSearch}
          onKeyDown={handleKeyDown}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default SearchForm;
