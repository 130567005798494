import React from "react";
import { Link } from "react-router-dom";
import Logo from "../logo.svg";

const Footer = () => {
  const iconsPath = process.env.PUBLIC_URL + "/icons/";

  const linksA = [
    { label: "Contact Us", to: "/account?tab=contact" },
    { label: "Refund Policy", to: "/refund-policy" },
    { label: "Terms of Use", to: "/terms-and-conditions" },
    { label: "Privacy Policy", to: "/privacy-policy" },
    { label: "Cookies Policy", to: "/cookies-policy" },
  ];

  const linksB = [
    { label: "Home", to: "/home" },
    { label: "About Us", to: "/aboutus" },
    { label: "FAQ", to: "/account?tab=contact" },
    { label: "Trending Events", to: "/discover" },
    { label: "Categories", to: "/discover" },
  ];

  const contacts = [
    {
      label: "Email",
      value: "moqah.pk@gmail.com",
      icon: "email.svg",
      onClick: () => window.open("mailto:moqah.pk@gmail.com", "_blank"),
    },
    {
      label: "Phone",
      value: "0335 5880869",
      icon: "phone.svg",
      onClick: () => window.open("tel:03355880869", "_blank"),
    },
    {
      label: "WhatsApp",
      value: "0335 5880869",
      icon: "wa.svg",
      onClick: () => window.open("https://api.whatsapp.com/send?phone=03355880869", "_blank"),
    },
    {
      label: "Instagram",
      value: "@moqah.pk",
      icon: "inst.svg",
      onClick: () => window.open("https://www.instagram.com/moqah.pk", "_blank"),
    },
    {
      label: "Facebook",
      value: "moqah.pk",
      icon: "fb.svg",
      onClick: () => window.open("https://www.facebook.com/moqah.pk", "_blank"),
    },
  ];

  return (
    <footer className="bg-[#04092C] text-white">
      <div className="container mx-auto px-4 py-4 lg:py-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Logo Section */}
          <div className="flex flex-col items-center md:items-start">
            <Link to="/home" className="flex items-center space-x-2 mb-3">
              <img src={Logo} alt="MOQAH.PK Logo" className="h-10 w-10 mr-2" />
              <h6 className="text-xl font-bold">MOQAH.PK</h6>
            </Link>
            <div className="text-gray-400 text-xs md:text-sm">
              Discover, Connect, and Experience Events Like Never Before
            </div>
          </div>

          {/* Quick Links */}
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-1">
              {linksA.map((link, index) => (
                <Link
                  key={index}
                  to={link.to}
                  className="hover:text-gray-300 text-xs md:text-sm transition-colors"
                >
                  {link.label}
                </Link>
              ))}
            </div>
            <div className="flex flex-col gap-1">
              {linksB.map((link, index) => (
                <Link
                  key={index}
                  to={link.to}
                  className="hover:text-gray-300 text-xs md:text-sm transition-colors"
                >
                  {link.label}
                </Link>
              ))}
            </div>
          </div>

          {/* Contact Information */}
          <div>
            <h4 className="text-lg font-semibold mb-3">Contact Us</h4>
            <div className="space-y-2">
              {contacts.map((contact, index) => (
                <div
                  key={index}
                  onClick={contact.onClick}
                  className="flex items-center space-x-2 hover:bg-gray-800 transition-colors p-2 rounded cursor-pointer"
                >
                  <img
                    src={iconsPath + contact.icon}
                    alt={`${contact.label} Icon`}
                    className="h-5 w-5"
                  />
                  <div>
                    <div className="text-xs text-gray-400">{contact.label}</div>
                    <div className="text-sm text-white font-medium">{contact.value}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="bg-[#3297FE] py-1">
        <div className="text-white text-center text-xs">
          &copy; {new Date().getFullYear()} <span className="font-bold">TECHUBBLE</span> Private Limited
        </div>
      </div>
    </footer>
  );
};

export default Footer;
