import React, { useState } from "react";

const CityCard = ({ imageUrl, heading, subheading, destination }) => {
  const [imgSrc, setImgSrc] = useState(imageUrl);

  const handleError = () => {
    setImgSrc("https://ik.imagekit.io/9vwcvqozx/moqah_bg.png?updatedAt=1732244718844");
  };

  const iconsPath = process.env.PUBLIC_URL + "/icons/";
  const buttonClass = (heading === "Islamabad" || heading === "Rawalpindi" || heading === "Lahore"|| heading === "Muzaffarabad") 
  ? "explore-button" 
  : "explore-button-grey";

const containerClass = (heading === "Islamabad" || heading === "Rawalpindi" || heading === "Lahore"|| heading === "Muzaffarabad") 
  ? "city-card-container" 
  : "city-card-container city-card-container-grey";

  return (
    <div className={containerClass}>
      <a href={destination}>
        <div className="image-wrapper">
          <img src={imgSrc} alt={heading + " image"} className="city-card-image" onError={handleError}/>
          <button className={buttonClass}>Explore</button>
        </div>
        <div className="city-card-content">
          <h2 className="city-card-heading">{heading}</h2>
          <div className="subhead-container">
            <p className="city-card-subheading">{subheading}</p>
            <a href="https://www.instagram.com/moqah.pk/"><img src={iconsPath + "options-button.svg"} alt="Options"/></a>
          </div>
        </div>
      </a>
    </div>
  );
};

export default CityCard;
