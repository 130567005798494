import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import TicketSelection from "../components/TicketSelection";
import ContactInformation from "../components/ContactInformation";
import PaymentMethod from "../components/PaymentMethod";
import PaymentInstructions from "../components/PaymentInstructions";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom"; // React Router hook
import axios from "axios";

// Helper function to format event name for URL
const formatEventNameForUrl = (name) => {
  return name?.toLowerCase().replace(/\s+/g, "-") || ""; // Safeguard for undefined names
};

const BookingPage = () => {
  const { eventName } = useParams();
  const [step, setStep] = useState(1);
  const [ticketSelections, setTicketSelections] = useState([]);
  const [ticketOptions, setTicketOptions] = useState([]);
  const [userDetails, setUserDetails] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    emailConfirmation: "",
    phone: "",
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const iconsPath = process.env.PUBLIC_URL + "/icons/";
  const location = useLocation();
  const [eventData, setEventData] = useState(location.state?.event || null);
  const navigate = useNavigate(); // Hook for navigation
  const operational = true;

  // Reference for the top of the page
  const topRef = useRef(null);

  // Scroll to the top of the container whenever the step changes
  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "instant" }); // Scroll instantly to the top
    }
  }, [step]);

  // Fetch ticket options
  useEffect(() => {
    const fetchTicketOptions = async () => {
      if (eventData?.id) {
        if (operational) {
          try {
            const ticketResponse = await axios.get(`${apiUrl}/ticket/options`, {
              params: { event_id: eventData.id },
            });
            setTicketOptions(
              ticketResponse.data.map((ticket) => ({
                id: ticket.ticket_title,
                type: ticket.ticket_title,
                price: ticket.total_ticket_price,
                description: ticket.ticket_description,
              }))
            );
          } catch (error) {
            console.error("Error fetching ticket options:", error);
          }
        } else {
          setTicketOptions([
            {
              id: 1,
              type: "VIP",
              price: 100,
              description: "this is the description",
            },
            {
              id: 2,
              type: "General",
              price: 50,
              description:
                "this is the description this is the description this is the description this is the description this is the description this is the description this is the description",
            },
            {
              id: 3,
              type: "Student",
              price: 30,
              description: "this is the description",
            },
          ]);
        }
      }
    };
    fetchTicketOptions();
  }, [eventData?.id]);

  // Fetch event details if eventData is missing or incomplete
  useEffect(() => {
    const fetchEventDetails = async () => {
      if (!eventData?.id) {
        try {
          const U_eventName = formatEventNameForUrl(eventName);
          const response = await axios.get(
            `${apiUrl}/event-details/${U_eventName}`
          );
          const eventData = response.data;
          console.log(eventData);
          let parsedLinks = {};
          try {
            parsedLinks = JSON.parse(eventData.links);
          } catch (error) {
            console.error("Failed to parse links:", error);
          }

          const checkImageExists = (url) => {
            return new Promise((resolve) => {
              const img = new Image();
              img.src = url;
              img.onload = () => resolve(true);
              img.onerror = () => resolve(false);
            });
          };

          const isValidImage = await checkImageExists(eventData.adv_banner);
          const backgroundImage = isValidImage
            ? eventData.adv_banner
            : "../../Adv_imgs/moqah_bg.png";

          setEventData({ ...eventData, parsedLinks, backgroundImage });
        } catch (error) {
          console.error("Error fetching event details:", error);
        }
      }
    };

    fetchEventDetails();
  }, [eventData, apiUrl, eventName]);

  // Fetch user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.post(`${apiUrl}/auth/verify-token`, {
            token,
          });
          if (response.status === 200) {
            setUserDetails({
              id: response.data.userDetails.id,
              firstName: response.data.userDetails.first_name,
              lastName: response.data.userDetails.last_name,
              email: response.data.userDetails.email,
              emailConfirmation: response.data.userDetails.email,
              phone: undefined,
            });
          } else {
            console.log("Failed to verify token");
            setUserDetails(undefined);
          }
        } catch (error) {
          console.log("Error verifying token:", error);
          setUserDetails(undefined);
        }
      } else {
        setUserDetails(undefined);
      }
    };

    fetchUserDetails();
  }, []);

  // Handle back button navigation
  const handleBackButtonNavigation = () => {
    if (step === 1) {
      window.location.href = `/event-details/${formatEventNameForUrl(
        eventName
      )}`;
    } else {
      setStep(step - 1);
    }
  };

  const generateInvoiceNumber = () => {
    const timestamp = Date.now();
    const randomPart = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
    const eventPrefix = eventData?.id?.toString().padStart(4, '0') || '0000';
    const invoiceNumber = `INV-${userDetails.id}-${eventPrefix}-${timestamp}-${randomPart}`;
    
    setInvoiceNumber(invoiceNumber);
  };

  // Navigation functions for steps
  const nextStep = () => {
    if (step < 4) setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  // Render the appropriate step component
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <TicketSelection
            eventData={eventData}
            ticketSelections={ticketSelections}
            ticketOptions={ticketOptions}
            setTicketSelections={setTicketSelections}
            nextStep={nextStep}
            prevStep={() => handleBackButtonNavigation()}
          />
        );
      case 2:
        return (
          <ContactInformation
            eventData={eventData}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            ticketSelections={ticketSelections}
            ticketOptions={ticketOptions}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 3:
        return (
          <PaymentMethod
            eventData={eventData}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            ticketSelections={ticketSelections}
            ticketOptions={ticketOptions}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        );
      case 4:
        return (
          <PaymentInstructions
            userDetails={userDetails}
            ticketSelections={ticketSelections}
            ticketOptions={ticketOptions}
            eventData={eventData}
            selectedPaymentMethod={selectedPaymentMethod}
            invoiceNumber={invoiceNumber}
            generateInvoiceNumber={generateInvoiceNumber}
            prevStep={prevStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Header searchBar={false} />
      <div className="payment-flow-container" ref={topRef}>
        <div className="payment-navigator">
          <button onClick={handleBackButtonNavigation} className="back-button">
            <img src={`${iconsPath}/arrow-blue.svg`} alt="back button" />
          </button>
          <h1 className="payment-step-heading">{`Step ${step}: ${
            [
              "Ticket Options",
              "Buyer Contact Information",
              "Payment Method",
              "Payment Instructions",
            ][step - 1]
          }`}</h1>
        </div>
        <div className="payment-step-container">{renderStep()}</div>
      </div>
      <Footer />
    </>
  );
};

export default BookingPage;
